import { Directive, HostListener, Input } from '@angular/core';
import { UsanaPhRetailRouteService } from '../services/usana-ph-retail-route.service';

@Directive({
  selector: 'a[uDcSRemoveUsanaPhSubdomainShop]',
})
export class RemoveUsanaPhSubdomainShopDirective {
  @Input() href: string;

  constructor(private usanaPhRetailRouteService: UsanaPhRetailRouteService) {}

  @HostListener('click', ['$event'])
  handleExternalLink(event: Event): void {
    const target = event.target as HTMLAnchorElement;
    const targetUrl = target.href;
    const linkTarget = target.target;

    if (targetUrl && linkTarget === '_blank' && this.usanaPhRetailRouteService.hasPhRetailSubdomain(targetUrl)) {
      event.preventDefault();

      const strippedSubdomainUrl = this.usanaPhRetailRouteService.removePhRetailSubdomain(targetUrl);
      window.open(strippedSubdomainUrl, '_blank');
    }
  }
}
