import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import { NgModule, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgbModalModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { CookieService } from 'ngx-cookie-service';

import { MenuMobileComponent } from './components/menu-mobile/menu-mobile.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { StickyBannerComponent } from './components/sticky-banner/sticky-banner.component';

import { ChooseLocaleToggleComponent } from './components/choose-locale/choose-locale-toggle.component';
import { ChooseLocaleComponent } from './components/choose-locale/choose-locale.component';

import { Title } from '@angular/platform-browser';
import { BaseComponent } from './components/base.component';
import { MenuDesktopComponent } from './components/menu-desktop/menu-desktop.component';
import { PageComponent } from './components/page.component';
import { SearchBoxComponent } from './components/search-box/search-box.component';
import { LocaleService } from './services/locale.service';
import { MarketService } from './services/market.service';
import { TranslationMapperService } from './services/translation-mapper.service';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { LocaleConfirmationComponent } from '@shared/lib/components/locale-confirmation/locale-confirmation.component';
import { DeferLoadModule } from '@shared/lib/modules/defer-load.module';
import { SafePipesModule } from '@shared/lib/pipes/safe-pipes.module';
import { UsanaMarketsPreloadModule } from '@usana/ux/markets';
import { UsanaMarketsRoutingModule } from '@usana/ux/markets/routing';
import { SsoAuthModule } from '@usana/ux/sso-auth';
import { PackagePrimeMode, TranslationConfig, UsanaTranslationsModule } from '@usana/ux/translations';
import { UsanaTranslationsPdfModule } from '@usana/ux/translations/pdf';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InViewportModule } from 'ng-in-viewport';
import { environment } from 'projects/usana/src/environments/environment';
import { DetermineMarketComponent } from './components/determine-market.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MenuMobileToggleComponent } from './components/menu-mobile/menu-mobile-toggle.component';
import { ModalComponent } from './components/modal/modal.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AuthService } from './services/auth.service';
import { ColorService } from './services/color.service';
import { DataService } from './services/data.service';
import { FloatingPresenceService } from './services/floating-presence.service';
import { LazyLoaderService } from './services/lazy-loader.service';
import { PageStructureService } from './services/page-structure.service';
import { UrlService } from './services/url.service';
import { SharedDotcomConstants } from './shared-dotcom.constants';
import { SharedFontAwesomeModule } from './shared-font-awesome.module';
import { TrimPipeModule } from './pipes/trim-pipe.module';
import { RemoveUsanaPhSubdomainShopDirective } from './directives/remove-usana-ph-subdomain-shop.directive';

const createApollo = (httpLink: HttpLink) => {
  const basic = setContext((_operation, _context) => ({
    headers: {
      Accept: 'application/json',
    },
  }));

  const link = ApolloLink.from([
    basic,
    httpLink.create({
      uri: environment.gatewayUrl,
    }),
  ]);

  return {
    link: link,
    cache: new InMemoryCache(),
  };
};

@NgModule({
  declarations: [
    BaseComponent,
    StickyBannerComponent,
    NavbarComponent,
    SearchBoxComponent,
    LoadingComponent,
    MenuMobileComponent,
    MenuMobileToggleComponent,
    MenuDesktopComponent,
    ModalComponent,
    ChooseLocaleComponent,
    ChooseLocaleToggleComponent,
    ClickOutsideDirective,
    PageComponent,
    DetermineMarketComponent,
    LocaleConfirmationComponent,
    RemoveUsanaPhSubdomainShopDirective,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    RouterModule,
    NgbModalModule,
    NgbCollapseModule,
    ReactiveFormsModule,
    UsanaMarketsPreloadModule,
    UsanaMarketsRoutingModule,
    UsanaTranslationsModule,
    UsanaTranslationsPdfModule,
    SsoAuthModule,
    InViewportModule,
    DeferLoadModule,
    SafePipesModule,
    TrimPipeModule,
    SharedFontAwesomeModule,
  ],
  providers: [
    CookieService,
    LocaleService,
    Title,
    MarketService,
    UrlService,
    AuthService,
    LazyLoaderService,
    TranslationMapperService,
    DataService,
    ColorService,
    FloatingPresenceService,
    PageStructureService,
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink],
    },
  ],
  bootstrap: [],
  exports: [
    UsanaMarketsPreloadModule,
    BaseComponent,
    StickyBannerComponent,
    NavbarComponent,
    SearchBoxComponent,
    LoadingComponent,
    MenuMobileComponent,
    MenuMobileToggleComponent,
    MenuDesktopComponent,
    ModalComponent,
    ChooseLocaleComponent,
    ChooseLocaleToggleComponent,
    ClickOutsideDirective,
    PageComponent,
    DetermineMarketComponent,
    SharedFontAwesomeModule,
    NgbModalModule,
    NgbCollapseModule,
    TrimPipeModule,
    RemoveUsanaPhSubdomainShopDirective,
  ],
})
export class SharedModule {
  constructor(sanitize: DomSanitizer, translationConfig: TranslationConfig) {
    // override the sanitize method so that it returns an empty string instead of null so that IE/Edge doesn't show "null" in the DOM
    // eslint-disable-next-line
    sanitize.sanitize = function (context: SecurityContext, value: SafeValue | string | null) {
      return (sanitize as any).__proto__.sanitize.call(sanitize as any, context, value) || '';
    };

    translationConfig.addPackage({
      pkg: SharedDotcomConstants.TRANSLATION_NAMESPACE,
      primeMode: PackagePrimeMode.ON_INIT,
      showTagWhenMissing: false,
    });
  }
}
