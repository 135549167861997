<div *ngIf="dataIsLoaded" [class.new-home-page]="isNewHomePageMarket$ | async">
  <ng-container *ngFor="let item of data; index as i">
    <span class="menu-item" *ngIf="hasInnerText(item.name)">
      <a
        uDcSRemoveUsanaPhSubdomainShop
        [attr.href]="item.url"
        (click)="handleLink($event, item.url, item.target)"
        attr.data-automation="{{ urlPathId(item.url) }}"
        target="{{ item.target }}"
        [ngClass]="{ 'active-link': isActiveLink(item) }"
        id="dropdownMenu{{ i }}"
        class="navLink"
        [innerHTML]="item.name | uppercase"
      ></a>
    </span>
  </ng-container>
</div>
