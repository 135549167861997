import { APP_BASE_HREF } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HostParts } from '@shared/lib/model/host-parts';
import { SharedDotcomConstants } from '@shared/lib/shared-dotcom.constants';
import {
  DOTCOM_LOCATION_TOKEN,
  ENVIRONMENT_CART_SERVICES_URL,
  ENVIRONMENT_HOST,
  ENVIRONMENT_SITE_DOMAIN,
} from '../shared.tokens';

@Injectable()
export class UrlService {
  private parts: HostParts;

  constructor(
    private router: Router,
    @Inject(DOTCOM_LOCATION_TOKEN) private location: Location,
    @Inject(ENVIRONMENT_HOST) private readonly environmentHost: string,
    @Inject(ENVIRONMENT_CART_SERVICES_URL) private readonly environmentCartServicesUrl: string,
    @Inject(ENVIRONMENT_SITE_DOMAIN) private readonly environmentSiteDomain: string,
    @Inject(APP_BASE_HREF) private readonly baseHref: string
  ) {
    const host: string = this.location.hostname;

    this.parts = this.getHostParts(host);

    if (this.parts.subdomain === '') {
      const path: string = this.location.href.substring(
        this.location.href.indexOf(this.parts.domain) + this.parts.domain.length
      );
      this.location.replace(`https://www.${this.parts.domain}${path}`);
    } else if (this.location.protocol !== 'https:') {
      const path: string = this.location.href.substring(
        this.location.href.indexOf(this.parts.domain) + this.parts.domain.length
      );
      this.location.replace(`https://${this.parts.subdomain}.${this.parts.domain}${path}`);
    }
  }

  getHubUrl(): string {
    return this.environmentHost + '/hub/#/home';
  }

  getCartServiceUrl(): string {
    return this.environmentCartServicesUrl;
  }

  getCartUserUrl(): string {
    return this.getCartServiceUrl() + '/user/';
  }

  /**
   * get a product url
   * @param usanaId
   * @param locale - this needs to be of the form en-US, this service isn't responsible for correcting it
   */
  getProductUrl(usanaId: string, locale: string): string {
    return (
      this.environmentHost +
      '/ux/cart/#!/' +
      locale +
      '/product/' +
      usanaId +
      '?' +
      SharedDotcomConstants.SHOPPER_SOURCE_PARAM +
      '=' +
      SharedDotcomConstants.SHOPPER_SOURCE_VALUE
    );
  }

  getDomain(): string {
    return this.parts.domain;
  }

  getSubdomain(): string {
    return this.parts.subdomain;
  }

  getHost(): string {
    return this.getDomainWithSubdomain(this.parts.subdomain);
  }

  getDomainWithSubdomain(subdomain: string): string {
    return `https://${subdomain}.${this.getDomain()}`;
  }

  getCurrentRouteUrl(): string {
    return this.router.url;
  }

  goTo(uri: string): void {
    this.location.assign(uri);
  }

  handleAnchorEvent(event: any, href?: string, target?: string): void {
    // currently this does nothing because it will break google analytics tracking
    // formatted this way so that if .value is undefined we also get _self
    const aTarget =
      target || (event.target.attributes.target ? event.target.attributes.target.value : undefined) || '_self';
    if (aTarget === '_self') {
      // we only handle self, otherwise let the browser handle this since it will open in a new tab
      const aHref = href || event.target.attributes.href.value;
      if (this.isInternalUrl(aHref)) {
        event.preventDefault();
        this.navigateToInternalUrl(aHref);
      }
    }
    // if we haven't called event.preventDefault then the browser will handle this anchor
  }

  isInternalUrl(url: string): boolean {
    return this.removeHost(url).startsWith(this.baseHref);
  }

  navigateToInternalUrl(url: string): void {
    const route = this.removeBaseHref(this.removeHost(url));
    this.router.navigateByUrl(route);
  }

  changeSubdomain(subdomain: string): void {
    if (subdomain?.length) {
      const path: string = this.location.href.substring(
        this.location.href.indexOf(this.parts.domain) + this.parts.domain.length
      );

      this.location.replace(`https://${subdomain}.${this.parts.domain}${path}`);
    }
  }

  private getHostParts(host: string): HostParts {
    const hostParts: string[] = host.split('.');
    const parts: HostParts = new HostParts();

    if (this.hostIsLocalhost(hostParts)) {
      parts.domain = 'localhost:' + this.location.port;
      parts.subdomain = null;
    } else {
      if (host.endsWith(this.environmentSiteDomain)) {
        parts.domain = this.environmentSiteDomain;
      } else {
        // everything past the last . but lets cut off everything after the / if it exists
        const domain: string = hostParts[hostParts.length - 2] + '.' + hostParts[hostParts.length - 1].split('/')[0];
        parts.domain = domain;
      }
      // this means the subdomain is the part before the domain
      parts.subdomain = host.substring(0, host.indexOf('.' + parts.domain));
    }

    return parts;
  }

  private hostIsLocalhost(hostParts: string[]) {
    return hostParts.length === 1 && hostParts[0].indexOf('localhost') === 0;
  }

  private removeHost(url: string): string {
    const host: string = this.getHost();
    if (url.startsWith(host)) {
      return url.substring(host.length);
    }
    return url;
  }

  private removeBaseHref(url: string): string {
    if (url.startsWith(this.baseHref)) {
      return url.substring(this.baseHref.length);
    }
    return url;
  }
}
