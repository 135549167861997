import { Injectable } from '@angular/core';
import { AuthService } from '@shared/lib/services/auth.service';
import { LocaleService } from '@shared/lib/services/locale.service';
import { UrlService } from '@shared/lib/services/url.service';
import { Subject, takeUntil } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/**
 * UsanaPhRetailRouteService
 *
 * A regulation states our usana.com subdomain needs to include uhsessentialhealthph for retail customers
 * in the Philippines. This service listens for the locale change and redirects to the correct subdomain.
 * Ignored for PWP (would conflict) and logged-in users.
 */
export class UsanaPhRetailRouteService {
  private readonly PH_RETAIL_SUBDOMAIN = 'uhsessentialhealthph';
  private readonly PH_LOCALES = ['enu-PH'];

  stop$: Subject<void>;

  constructor(private authService: AuthService, private urlService: UrlService, private localeService: LocaleService) {}

  listenForRetailRoute(): void {
    this.stop$ = new Subject<void>();

    this.localeService
      .getLocaleObservable()
      .pipe(takeUntil(this.stop$))
      .subscribe((locale) => {
        if (this.PH_LOCALES.includes(locale) && this.shouldRedirectToPhRetailSubdomain()) {
          this.stopListeningForRetailRoute();
          this.urlService.changeSubdomain(this.PH_RETAIL_SUBDOMAIN);
        }
      });
  }

  stopListeningForRetailRoute(): void {
    if (this.stop$) {
      this.stop$.next();
      this.stop$.complete();
    }
  }

  hasPhRetailSubdomain(url: string): boolean {
    return !!url && url.includes(`${this.PH_RETAIL_SUBDOMAIN}.`);
  }

  removePhRetailSubdomain(url: string): string {
    return url?.replace(`${this.PH_RETAIL_SUBDOMAIN}.`, 'www.');
  }

  private shouldRedirectToPhRetailSubdomain(): boolean {
    const isLoggedIn = this.authService.isLoggedIn();

    const subdomain = this.urlService.getSubdomain();
    const isPwp = !!subdomain && subdomain !== 'www';

    return !isLoggedIn && !isPwp;
  }
}
